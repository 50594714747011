import React, { Component } from "react";
import Dimensions from "react-dimensions";
import Link from "./Link";

class Menu extends Component {
  constructor(props) {
    super(props);

    window.menu = this;

    this.state = {
      menuClass: "menu-item",
      mobileClose: "",
      hoverItem: null,
    };

    // this.onDeleteSelection = this.onDeleteSelection.bind(this);
    this.onSubMenuClick = this.onSubMenuClick.bind(this);
    this.rendersubMenuItems = this.rendersubMenuItems.bind(this);
    this.onRfidChanged = this.onRfidChanged.bind(this);
  }

  onRfidChanged(key) {
    if (key && this.props.roomConfiguration) {
      let selectedRoomConfigurationVariation = this.props.roomConfiguration.roomConfigurationVariations.find(
        (v) => {
          if (v.rfidKey != null) {
            let split = v.rfidKey.split(",");
            return split.indexOf(key) !== -1;
          } else {
            return false;
          }
        }
      );

      if (selectedRoomConfigurationVariation) {
        this.onSubMenuClick(selectedRoomConfigurationVariation);
      }
    }
  }

  onSubMenuClick(variation) {
    if (
      this.props.activePlanId != null &&
      this.props.activePlanId !== 0 &&
      this.props.customerSelections &&
      this.props.customerSelections.length > 0
    ) {
      let tempActivePlan =
        this.props.customerSelections &&
        this.props.customerSelections.length > 0 &&
        this.props.customerSelections.find(
          (cs) => cs.id === this.props.activePlanId
        );

      if (tempActivePlan) {
        if (tempActivePlan.customerSelectionVariations) {
          let selectedRoomConfigurationVariation = this.props.roomConfiguration.roomConfigurationVariations.find(
            (v) => v.id === variation.id
          );

          let tempVariations = tempActivePlan.customerSelectionVariations.filter(
            (v) => {
              let roomConfigurationVariation = this.props.roomConfiguration.roomConfigurationVariations.find(
                (rcv) => rcv.id === v.roomConfigurationVariationId
              );

              return (
                roomConfigurationVariation &&
                selectedRoomConfigurationVariation &&
                roomConfigurationVariation.roomDefinitionLayerId !==
                  selectedRoomConfigurationVariation.roomDefinitionLayerId
              );
            }
          );

          tempVariations.push({
            roomDefinitionLayerId:
              selectedRoomConfigurationVariation.roomDefinitionLayerId,
            roomConfigurationVariationId: variation.id,
            customerSelectionId: this.props.activePlanId,
          });

          this.props.onCustomerSelectionsChanged(tempVariations);
        } else {
          this.props.onCustomerSelectionsChanged([
            {
              roomDefinitionLayerId: variation.roomDefinitionLayerId,
              roomConfigurationVariationId: variation.id,
              customerSelectionId: this.props.activePlanId,
            },
          ]);
        }
      }
    }
  }

  renderMenu() {
    let menuItems = [];

    if (
      this.props.roomConfiguration &&
      this.props.roomConfiguration.roomConfigurationVariations
    ) {
      this.props.roomConfiguration.roomConfigurationVariations.forEach((v) => {
        if (
          menuItems.some((m) => m.name === v.roomDefinitionLayerName) === false
        ) {
          menuItems.push({
            id: v.roomDefinitionLayerId,
            name: v.roomDefinitionLayerName,
          });
        }
      });
    }

    if (menuItems) {
      return menuItems.map((item) => {
        return (
          <li
            key={item.id}
            className={
              this.props.activeMenu && this.props.activeMenuId === item.id
                ? "menu-item active"
                : "menu-item"
            }
          >
            <Link
              onClick={() => {
                this.props.onMenuClick(item.id);
              }}
            >
              {item.name}
            </Link>
          </li>
        );
      });
    }
  }

  onMouseOverSubmit(hoverItem) {
    // early exit for mobile view
    if (this.props.containerWidth >= 768) {
      return;
    }

    this.setState({
      hoverItem,
    });
  }

  onMouseMoveSubmit(e) {
    if (this.props.containerWidth >= 768) {
      return;
    }

    const { pageX, pageY } = e;
    this.setState({
      pageX: pageX + 10,
      pageY: pageY + 10,
    });
  }

  onMouseOutSubmit() {
    this.setState({
      hoverItem: null,
    });
  }

  rendersubMenuItems() {
    if (
      this.props.activeMenuId &&
      this.props.roomConfiguration &&
      this.props.roomConfiguration.roomConfigurationVariations
    ) {
      const subMenu = this.props.roomConfiguration.roomConfigurationVariations.find(
        (c) => c.roomDefinitionLayerId === this.props.activeMenuId
      );

      const subMenuName = subMenu ? subMenu.roomDefinitionLayerName : "";

      const subItems = this.props.roomConfiguration.roomConfigurationVariations
        .filter((m) => m.roomDefinitionLayerId === this.props.activeMenuId)
        .map((item) => {
          let itemClass = "item-block";
          if (this.props.customerSelections) {
            let activePlan = this.props.customerSelections.find(
              (cs) => cs.id === this.props.activePlanId
            );

            if (
              activePlan &&
              activePlan.customerSelectionVariations &&
              activePlan.customerSelectionVariations.some(
                (v) => v.roomConfigurationVariationId === item.id
              )
            ) {
              itemClass = itemClass + " active";
            }
          }

          return (
            <div
              key={item.id}
              onMouseOver={() => {
                this.onMouseOverSubmit(item);
              }}
              onMouseMove={(e) => {
                this.onMouseMoveSubmit(e);
              }}
              onMouseOut={() => {
                this.onMouseOutSubmit();
              }}
              onClick={() => this.onSubMenuClick(item)}
            >
              <div
                style={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_APIBASE + item.materialImageUrl
                  })`,
                }}
                className={itemClass}
              />
              <div className="item-label">{item.name}</div>
            </div>
          );
        });

      return (
        <div>
          <div
            className={
              this.state.hoverItem !== null
                ? "item-preview active"
                : "item-preview"
            }
            style={{ left: this.state.pageX, top: this.state.pageY }}
          >
            <div
              className="preview-img"
              style={{
                backgroundImage:
                  this.state.hoverItem &&
                  `url(${
                    process.env.REACT_APP_APIBASE +
                    this.state.hoverItem.materialImageUrl
                  })`,
              }}
            />
            <p className="preview-name">
              {this.state.hoverItem && this.state.hoverItem.name}
            </p>
          </div>
          <div className="sub-items-holder">
            <div>
              {subMenuName}
              <span>
                {subItems.length} {this.props.translationResources["ITEMS"]}
              </span>
            </div>
            <div
              className="close-btn"
              onClick={() => {
                this.props.onMenuCloseClick();
              }}
            />
          </div>
          <div className="sub-items">{subItems}</div>
        </div>
      );
    }
  }

  renderMobileNote() {
    if (this.props.activeMenuId !== 0) {
      return;
    }

    return (
      <p className={`${this.props.subMenuOpen} mobile-selection-alert`}>
        {this.props.translationResources["please select a category"]}
      </p>
    );
  }

  render() {
    let user =
      this.props.translationResources["Hello"] &&
      this.props.translationResources["Guest"];
    if (this.props.userInfo) {
      user = this.props.userInfo.name;
    }

    let username =
      this.props.translationResources["Hello"] &&
      `${this.props.translationResources["Hello"]} ${user}!`;

    return (
      <div className="side-bar">
        {this.renderMobileNote()}
        {this.props.activeMenu && (
          <div className={`${this.props.subMenuOpen} sub-menu-holder`}>
            <div
              className={`${this.props.subMenuOpen} ${this.state.mobileClose} sub-menu`}
            >
              {this.rendersubMenuItems()}
            </div>
          </div>
        )}
        <div className="menu-holder">
          <div className="menu-scroll-holder">
            <div
              className={`${this.props.subMenuOpen} mobile-closer`}
              onClick={() => {
                this.props.onMenuCloseClick();
              }}
            />
            <div className="user-holder">
              <p className="username">{username}</p>
              <Link
                onClick={
                  this.props.userInfo
                    ? this.props.onLogoutClick
                    : this.props.onShowLoginClick
                }
                className="grey-links"
              >
                {this.props.userInfo
                  ? this.props.translationResources["LOGOUT"]
                  : this.props.translationResources["LOGIN"]}
              </Link>
            </div>
            <ul className="menu">{this.renderMenu()}</ul>
          </div>
        </div>
        <div className="action-holder">
          {this.props.changedPlans &&
          this.props.changedPlans.find(
            (cp) => cp === this.props.activePlanId
          ) ? (
            <Link
              onClick={this.props.onRevertClick}
              className="grey-links btn-mobile-revert"
            >
              {this.props.translationResources["REVERT"]}
            </Link>
          ) : (
            <Link
              onClick={this.props.onCreateNewClick}
              className="grey-links btn-mobile-add"
            >
              {this.props.translationResources["CREATE NEW PLAN"]}
            </Link>
          )}
          <Link
            onClick={
              this.props.activePlanId < 0
                ? this.props.onSaveClick
                : this.props.changedPlans &&
                  this.props.changedPlans.find(
                    (cp) => cp === this.props.activePlanId
                  )
                ? this.props.onSaveClick
                : this.props.onShowDisclaimerClick
            }
            className="btn"
          >
            {this.props.activePlanId < 0
              ? this.props.translationResources["SAVE"]
              : this.props.changedPlans &&
                this.props.changedPlans.find(
                  (cp) => cp === this.props.activePlanId
                )
              ? this.props.translationResources["UPDATE"]
              : this.props.translationResources["ORDER"]}
          </Link>
        </div>
      </div>
    );
  }
}

export default Dimensions({
  className: "dimension-holder",
  containerStyle: {},
})(Menu);
