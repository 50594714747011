import React, { Component } from "react";
import Modal from "./Modal";

import success_icon from "../images/confirmation-success.png";
import fail_icon from "../images/confirmation-fail.png";

class Confirmation extends Component {
  render() {
    const confirmation_icon =
      this.props.confirmationResponseType === "1" ? success_icon : fail_icon;

    const confirmation_message =
      this.props.confirmationResponseType === "1"
        ? this.props.translationResources["Your order is confirmed"]
        : this.props.confirmationResponseType === "2"
        ? this.props.translationResources[
            "Your selected variations can't be found."
          ]
        : this.props.confirmationResponseType === "3"
        ? this.props.translationResources["Your selection can't be found."]
        : this.props.translationResources["Your order isn't confirmed"];

    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title={this.props.translationResources["Thank You"]}
        content={
          <div className="box-content-holder">
            <div className="box confirmation">
              <div className="box-content">
                <p className="confirmation-text">
                  <h2>{confirmation_message}</h2>
                  <img
                    src={confirmation_icon}
                    alt={this.props.translationResources["Confirmation"]}
                  />
                  {this.props.confirmationResponseType === "1" && (
                    <p>
                      {
                        this.props.translationResources[
                          "Our sales manager will get in touch with you."
                        ]
                      }
                    </p>
                  )}
                </p>
              </div>
            </div>
          </div>
        }
        callback={this.props.callback}
      />
    );
  }
}

export default Confirmation;
