import React, { Component } from "react";
import Link from "./Link";
import Modal from "./Modal";
import axios from "axios";

class DeleteCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePlanId: null,
    };

    this.onDeleteSelection = this.onDeleteSelection.bind(this);
  }

  onDeleteSelection = () => {
    if (this.props.activePlanId > 0) {
      let selection = {
        customerSelectionId: this.props.customerSelections.find(
          (s) => s.id === this.props.activePlanId
        ).id,
      };

      axios
        .delete(
          process.env.REACT_APP_APIBASE +
          "/api/v2/DeleteCustomerSelection/" +
          selection.customerSelectionId,
          { headers: { "Registration-Key": this.props.registrationKey } }
        )
        .then((response) => {
          this.props.onDeleteSelectionSuccessfull(this.props.activePlanId);
        });
    } else {
      this.props.onDeleteSelectionSuccessfull(this.props.activePlanId);
    }
  };

  render() {
    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title={this.props.translationResources["Delete Check!"]}
        contentClass="delete-holder"
        content={
          <div className="box-content-holder">
            <div className="box">
              <div className="box-content">
                <h2>{this.props.translationResources["Are you sure?"]}</h2>
                <p>
                  {
                    this.props.translationResources[
                    "You are deleting the saved plan! You'll lose all selections. It's not returnable action!"
                    ]
                  }
                </p>
              </div>
              <div className="btn-holder delete-btns">
                <Link
                  onClick={() => this.props.closeDelete()}
                  className="btn quit-delete-btn"
                >
                  {this.props.translationResources["Don't delete"]}
                </Link>
                <Link
                  onClick={() => {
                    this.onDeleteSelection();
                  }}
                  className="btn"
                >
                  {this.props.translationResources["Delete"]}
                </Link>
              </div>
            </div>
          </div>
        }
        callback={this.props.callback}
      />
    );
  }
}

export default DeleteCheck;
