import React, { Component } from "react";

class RoomSelector extends Component {

  render() {
    return (
      <div className="room-selector">
        {
          this.props.roomConfigurations && this.props.roomConfigurations.map(rc => {
            const itemClass = this.props.roomConfiguration && this.props.roomConfiguration.id === rc.id ? "planner-box active" : "planner-box";

            return (
              <div
                key={rc.id}
                onClick={() => this.props.onConfigurationItemClick(rc)}
                className={itemClass}
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_APIBASE + rc.baseImageUrl})`
                }}
              >
                <p>{rc.name}</p>
              </div>)
          })
        }
      </div>);
  }
}

export default RoomSelector;
