import React, { Component } from "react";
import Link from "./Link";
import Modal from "./Modal";
var HtmlToReactParser = require("html-to-react").Parser;

class Register extends Component {
  render() {
    let htmlToReactParser = new HtmlToReactParser();
    const confirmOrderText = htmlToReactParser.parse(
      this.props.roomConfirmationSettings.confirmOrderText
    );

    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title={this.props.translationResources["Continue By"]}
        contentClass="register-holder"
        content={
          <div>
            <Link
              onClick={() => {
                this.props.goBack();
              }}
              class="go-back-btn"
            >
              {this.props.translationResources["Go Back"]}
            </Link>
            <div className="box-content-holder register">
              <div className="box">
                <div className="box-content">
                  <h2>{this.props.translationResources["Create New"]}</h2>
                  <p className="uppercase">
                    {
                      this.props.translationResources[
                        "WOULD YOU LIKE TO TRY ANOTHER LAYOUT AND MAKE A COMPARISON?"
                      ]
                    }
                  </p>
                </div>
                <div className="btn-holder">
                  <Link
                    onClick={this.props.onCreateNewClick}
                    className="btn small-btn"
                  >
                    +
                  </Link>
                </div>
              </div>
              <div className="box">
                <div className="box-content">
                  <h2>{this.props.translationResources["Confirm Order"]}</h2>
                  <p className="uppercase">{confirmOrderText}</p>
                </div>
                <div className="btn-holder">
                  <Link
                    onClick={this.props.onShowDisclaimerClick}
                    className="btn"
                  >
                    {this.props.translationResources["CONFIRM"]}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
        callback={this.props.callback}
      />
    );
  }
}

export default Register;
