import React, { Component } from "react";
import Link from "./Link";
import axios from "axios";
import Modal from "./Modal";
import { withSnackbar } from "notistack";
import SimpleReactValidator from "simple-react-validator";

class Save extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutName: null,
    };

    this.validator = new SimpleReactValidator();

    this.onSaveClick = this.onSaveClick.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onTextChange(e) {
    this.setState({ layoutName: e.target.value });
  }

  onSaveClick() {
    if (this.validator.allValid()) {
      let tempCustomerSelectionVariations = [];

      if (this.props.activePlanId != null && this.props.activePlanId !== 0) {
        tempCustomerSelectionVariations = this.props.customerSelections.find(
          (s) => s.id === this.props.activePlanId
        ).customerSelectionVariations;
      }

      let selection = {
        name: this.state.layoutName,
        customerId: this.props.userInfo.id,
        customerSelectionVariations: tempCustomerSelectionVariations,
      };

      axios
        .post(
          process.env.REACT_APP_APIBASE + "/api/v2/SaveCustomerSelection",
          selection,
          { headers: { "Registration-Key": this.props.registrationKey } }
        )
        .then((response) => {
          this.props.onSaveSuccessfull(response.data);
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title={this.props.translationResources["Save It"]}
        content={
          <div className="box-content-holder">
            <div className="box">
              <div className="box-content">
                <h2>{this.props.translationResources["Save Your Layout"]}</h2>
                <input
                  type="text"
                  placeholder={this.props.translationResources["LAYOUT NAME"]}
                  onChange={this.onTextChange}
                />
                {this.validator.message(
                  "layoutName",
                  this.state.layoutName,
                  "required|min:3"
                )}
              </div>
              <div className="btn-holder align-right">
                <Link onClick={this.onSaveClick} className="btn">
                  {this.props.translationResources["SAVE"]}
                </Link>
              </div>
            </div>
          </div>
        }
        callback={this.props.callback}
      />
    );
  }
}

export default withSnackbar(Save);
