import React, { Component } from "react";

import rotatingPhone from "../images/rotating-phone.png";

class LandscapeCheck extends Component {
  render() {
    return (
      <div className="landscape-check">
        <div className="landscape-check-content">
          <img src={rotatingPhone} alt="Rotate your device!" />
          <p>
            {
              this.props.translationResources[
                "Please rotate your device for the best usage!"
              ]
            }
          </p>
        </div>
      </div>
    );
  }
}

export default LandscapeCheck;
