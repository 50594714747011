import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import ScenePreview from "./ScenePreview";
import Menu from "./Menu";
import Login from "./Login";
import Save from "./Save";
import SignUp from "./SignUp";
import Confirmation from "./Confirmation";
import MobileHeader from "./MobileHeader";
import axios from "axios";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Register from "./Register";
import { withSnackbar } from "notistack";
import Disclaimer from "./Disclaimer";
import Saved from "./Saved";
import RoomConfigurationsMobile from "./RoomConfigurationsMobile";
import * as utils from "../utils/utils";
import Summary from "./Summary";
import DeleteCheck from "./DeleteCheck";
import PrivateRoute from "./PrivateRoute";

class Scene extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    window.scene = this;
    const configurationKeyParam = utils.getQueryVariable("configurationKeys");
    const registrationKey = utils.getQueryVariable("registrationKey");
    let configurationKeys = [];

    var shouldStoreCookie = true;

    const storeCookieParam = utils.getQueryVariable("cookie");
    if (storeCookieParam != null && !storeCookieParam) {
      shouldStoreCookie = false;
    }

    const confirmationParam = utils.getQueryVariable("confirmed");
    let isDisplayConfirmPage = confirmationParam && confirmationParam !== "";

    if (configurationKeyParam && configurationKeyParam.length > 0) {
      configurationKeys = configurationKeyParam.split(",");
    }

    this.state = {
      activeMenu: null,
      activePlanId: 0,
      customerSelections: [],
      roomConfigurations: [],
      roomConfiguration: null,
      userInfo: null,
      subMenuOpen: "",
      showConfirmation: isDisplayConfirmPage,
      confirmationResponseType: confirmationParam,
      configurationKeys,
      registrationKey,
      shouldStoreCookie,
      changedPlans: [],
      roomConfirmationSettings: {},
      isThumbnailInvalid: true,
    };

    this.fabricCanvas = null;
    this.canvasScale = 1.0;
  }

  onCanvasInit = (canvas, initialScale) => {
    this.fabricCanvas = canvas;
    this.canvasScale = initialScale;
  };

  onShowRoomConfigurationsMobileClick = () => {
    this.setState({
      showRoomConfigurationsMobile: true,
    });
  };

  invalidateThumbnail = () => {
    this.setState({ isThumbnailInvalid: true });

    this.refs.scenePreview.fabricCanvas.renderAll();
  };

  afterRender = () => {
    if (this.state.isThumbnailInvalid) {
      let activeCustomerSelection = this.state.customerSelections.find(
        (cs) => cs.id === this.state.activePlanId
      );
      if (activeCustomerSelection) {
        activeCustomerSelection.thumbnailUrl = this.reRenderThumbnail();

        let tempCustomerSelections = this.state.customerSelections.filter(
          (cs) => cs.id !== this.state.activePlanId
        );

        tempCustomerSelections.push(activeCustomerSelection);

        this.setState({
          customerSelections: tempCustomerSelections,
          isThumbnailInvalid: false,
        });
      }
    }
  };

  onPlanItemMobileClick = (activePlanId) => {
    this.setState({
      showPlan: false,
      activePlanId: activePlanId,
    });
  };

  onShowDisclaimerClick = () => {
    this.props.history.push("/disclaimer" + window.location.search);
  };

  onShowSummaryClick = () => {
    this.props.history.push("/summary" + window.location.search);
  };

  onShowPlanClick = () => {
    this.setState({ showPlan: true });
  };

  onConfigurationItemClick = (configuration) => {
    let menuItems = [];
    let subMenuItems = [];
    configuration.roomConfigurationVariations.forEach((v) => {
      if (
        menuItems.some((m) => m.name === v.roomDefinitionLayerName) === false
      ) {
        menuItems.push({
          id: v.roomDefinitionLayerId,
          name: v.roomDefinitionLayerName,
        });
      }

      subMenuItems.push({
        id: v.id,
        name: v.name,
        parentId: v.roomDefinitionLayerId,
      });
    });

    this.setState((state) => {
      state.roomConfiguration = configuration;
      state.menuItems = menuItems;
      state.subMenuItems = subMenuItems;
      state.activeMenu = null;
      state.showRoomConfigurationsMobile = false;
      state.subMenuOpen = null;

      this.activateLastCustomerSelection(state);

      return state;
    }, this.getRoomDefinition);
  };

  onCustomerSelectionsChanged = (variations) => {
    let tempCustomerSelection;
    let tempCustomerSelections = this.state.customerSelections && [
      ...this.state.customerSelections,
    ];
    let tempActivePlanId = this.state.activePlanId;

    if (
      this.state.customerSelections &&
      this.state.customerSelections.length > 0
    ) {
      let activeCustomerSelection = this.state.customerSelections.find(
        (s) => s.id === variations[0].customerSelectionId
      );
      if (activeCustomerSelection) {
        tempCustomerSelection = { ...activeCustomerSelection };
        tempCustomerSelection.customerSelectionVariations = variations;
      }
    }

    if (tempCustomerSelections) {
      tempCustomerSelections = tempCustomerSelections.filter(
        (cs) => cs.id !== variations[0].customerSelectionId
      );
      tempCustomerSelections.push(tempCustomerSelection);
    } else {
      tempCustomerSelections = [tempCustomerSelection];
    }

    this.setState({
      customerSelections: tempCustomerSelections,
      activePlanId: tempActivePlanId,
    });

    this.invalidateThumbnail();

    this.handlePlanChanged(tempActivePlanId);
  };

  handlePlanChanged(activePlanId) {
    if (!this.state.changedPlans.find((cp) => cp === activePlanId)) {
      let tempChangedPlans = [activePlanId, ...this.state.changedPlans];
      this.setState({ changedPlans: tempChangedPlans });
    }
  }

  onRevertClick = () => {
    let activePlan = this.state.customerSelections.find(
      (cs) => cs.id === this.state.activePlanId
    );
    let otherPlans = this.state.customerSelections.filter(
      (cs) => cs.id !== this.state.activePlanId
    );
    let revertedCustomerSelectionVariations = [];

    activePlan.customerSelectionVariations.forEach((csv) => {
      let oldVariation = this.state.roomConfiguration.roomConfigurationVariations.find(
        (v) => v.id === csv.roomConfigurationVariationId
      );
      let newVariationId;

      if (this.state.activePlanId < 0) {
        newVariationId = this.state.roomConfiguration.roomConfigurationVariations.find(
          (v) => v.roomDefinitionLayerId === oldVariation.roomDefinitionLayerId
        ).id;
      } else {
        let orijinalCustomerSelection = this.state.originalCustomerSelections.find(
          (cs) => cs.id === this.state.activePlanId
        );
        orijinalCustomerSelection.customerSelectionVariations.forEach((v) => {
          let roomDefinitionLayerId = this.state.roomConfiguration.roomConfigurationVariations.find(
            (variation) => variation.id === v.roomConfigurationVariationId
          ).roomDefinitionLayerId;

          if (roomDefinitionLayerId === oldVariation.roomDefinitionLayerId) {
            newVariationId = v.roomConfigurationVariationId;
          }
        });
      }

      revertedCustomerSelectionVariations.push({
        customerSelectionId: this.state.activePlanId,
        roomConfigurationVariationId: newVariationId,
      });
    });

    activePlan.customerSelectionVariations = revertedCustomerSelectionVariations;

    this.setState(
      {
        customerSelections: [...otherPlans, activePlan],
        changedPlans: this.state.changedPlans.filter(
          (p) => p !== this.state.activePlanId
        ),
      },
      this.invalidateThumbnail
    );
  };

  reRenderThumbnail() {
    var resizedCanvas = document.createElement("canvas");
    var resizedContext = resizedCanvas.getContext("2d");
    resizedCanvas.height = "150";
    resizedCanvas.width = "260";
    var canvas = document.getElementById("canvasHolder");
    resizedContext.drawImage(canvas, 0, 0, 260, 150);
    var myResizedDataUrl = resizedCanvas.toDataURL("image/png");

    return myResizedDataUrl;
  }

  onConfirmationSent = () => {
    this.props.enqueueSnackbar(
      this.props.translationResources[
      "Confirmation mail sent. Please check your emails."
      ]
    );
  };

  onCreateNewClick = () => {
    this.setState(
      (state) => {
        let newSelection = this.initializeNewCustomerSelection(state);

        state.customerSelections = [...state.customerSelections, newSelection];
        state.activePlanId = newSelection.id;

        return state;
      },
      () => {
        this.props.history.push("/" + window.location.search);

        setTimeout(this.invalidateThumbnail, 100);
      }
    );
  };

  initializeNewCustomerSelection(state) {
    let newId = Math.floor(Math.random() * 100000) - 100000;
    let defaultVariations = [];
    state.roomConfiguration &&
      state.roomConfiguration.roomConfigurationVariations.forEach(
        (variation) => {
          if (
            defaultVariations.some(
              (dv) =>
                dv.roomDefinitionLayerId === variation.roomDefinitionLayerId
            ) === false
          ) {
            let newVariation = {
              customerSelectionId: newId,
              roomConfigurationVariationId: variation.id,
            };
            defaultVariations = [
              ...defaultVariations,
              {
                roomDefinitionLayerId: variation.roomDefinitionLayerId,
                variation: newVariation,
              },
            ];
          }
        }
      );

    let newSelection = {
      id: newId,
      name: this.props.translationResources["new plan"],
      customerSelectionVariations: defaultVariations.map((dv) => {
        return dv.variation;
      }),
    };
    let tempCustomerSelections = this.state.customerSelections.filter(
      (cs) => cs.id !== newSelection.id
    );
    state.customerSelections = [...tempCustomerSelections, newSelection];

    return newSelection;
  }

  onDeleteSelectionSuccessfull = (id) => {
    this.props.enqueueSnackbar(
      this.props.translationResources["Plan deleted."]
    );

    this.setState((state) => {
      state.customerSelections = state.customerSelections.filter(
        (s) => s.id !== id
      );

      state.originalCustomerSelections = state.customerSelections;

      state.changedPlans = state.changedPlans.filter((p) => p !== id);

      this.activateLastCustomerSelection(state);

      return state;
    }, this.props.history.push("/" + window.location.search));
  };

  onLogoutClick = () => {
    this.setState(
      (state) => {
        let newSelection = this.initializeNewCustomerSelection(state);

        state.customerSelections = [newSelection];
        state.activePlanId = newSelection.id;

        state.userInfo = null;
        state.activeMenu = null;

        return state;
      },
      () => {
        this.props.cookies.remove("roomPlannerEmail", { path: "/" });
        setTimeout(this.invalidateThumbnail, 100);
      }
    );
  };

  onSignUpSuccessfull = (customer) => {
    this.setState(
      {
        userInfo: {
          id: customer.id,
          name: customer.name,
          surname: customer.surname,
        },
        showSave: this.state.showSaveAfterSignup,
        showSaveAfterSignup: false,
      },
      () => {
        if (this.state.showSave) {
          this.props.history.push("/save" + window.location.search);
        } else {
          this.props.history.push("/" + window.location.search);
        }
      }
    );
  };

  onSaveSuccessfull = (customerSelection) => {
    this.props.enqueueSnackbar(
      `${this.props.translationResources["Plan"]} ${this.state.activePlanId < 0
        ? this.props.translationResources["saved"]
        : this.props.translationResources["updated"]
      }.`
    );

    this.setState(
      (state) => {
        let oldPlanId = state.activePlanId;
        state.showRegister = oldPlanId < 0;
        state.activePlanId = customerSelection.id;

        if (state.changedPlans.find((cp) => cp === state.activePlanId)) {
          state.changedPlans = state.changedPlans.filter(
            (cp) => cp !== state.activePlanId
          );
        }

        let tempSelections = [];

        if (
          this.state.customerSelections.some(
            (s) => s.id === customerSelection.id
          )
        ) {
          tempSelections = this.state.customerSelections.filter(
            (s) => s.id !== customerSelection.id
          );
        } else {
          tempSelections = [...this.state.customerSelections];
        }

        tempSelections = tempSelections.filter((s) => s.id !== oldPlanId);

        tempSelections.push(customerSelection);

        state.customerSelections = tempSelections;

        state.originalCustomerSelections = tempSelections;

        return state;
      },
      () => {
        this.saveThumbnail(customerSelection);

        if (this.state.showRegister) {
          this.props.history.push("/register" + window.location.search);
        } else {
          this.props.history.push("/" + window.location.search);
        }
      }
    );
  };

  customBlob(canvas, callback, type, quality) {
    setTimeout(function () {
      var binStr = atob(canvas.toDataURL(type, quality).split(",")[1]),
        len = binStr.length,
        arr = new Uint8Array(len);

      for (var i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      callback(new Blob([arr], { type: type || "image/png" }));
    });
  }

  saveThumbnail(customerSelection) {
    var canvas = document.getElementById("canvasHolder");
    const _state = this.state;
    this.customBlob(canvas, function (blob) {
      let data = new FormData();
      data.append("customerSelectionThumbnail", blob);
      data.append("customerSelectionId", customerSelection.id);

      axios.post(
        process.env.REACT_APP_APIBASE +
        "/api/v2/SaveCustomerSelectionThumbnail",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Registration-Key": _state.registrationKey
          },
        }
      );
    });

    customerSelection.thumbnailUrl = canvas.toDataURL("image/jpeg");

    let tempCustomerSelections = this.state.customerSelections.filter(
      (cs) => cs.id !== customerSelection.id
    );
    tempCustomerSelections = [...tempCustomerSelections, customerSelection];

    this.setState({ customerSelection: tempCustomerSelections });
  }

  onSaveClick = () => {
    let selection =
      this.state.customerSelections &&
      this.state.customerSelections.find(
        (s) => s.id === this.state.activePlanId
      );

    if (
      selection == null ||
      selection.customerSelectionVariations == null ||
      selection.customerSelectionVariations.length === 0
    ) {
      this.props.enqueueSnackbar(
        this.props.translationResources["Please select a variation first."],
        {
          variant: "error",
        }
      );
      return;
    }

    if (this.state.userInfo && this.state.userInfo.id) {
      if (this.state.activePlanId > 0) {
        let tempSelection = { ...selection };
        tempSelection.thumbnailUrl = null;

        axios
          .post(
            process.env.REACT_APP_APIBASE + "/api/v2/SaveCustomerSelection",
            tempSelection,
            { headers: { "Registration-Key": this.state.registrationKey } }
          )
          .then((response) => {
            this.onSaveSuccessfull(response.data);
          });
      } else {
        this.props.history.push("/save" + window.location.search);
      }
    } else {
      this.setState(
        {
          showSaveAfterSignup: true,
        },
        this.props.history.push("/signup" + window.location.search)
      );
    }
  };

  onShowLoginClick = () => {
    this.props.history.push("/login" + window.location.search);
  };

  onShowSignUpClick = () => {
    this.props.history.push("/signup" + window.location.search);
  };

  onLoginSuccessfull = (customer) => {
    this.setState(
      (state) => {
        state.userInfo = {
          id: customer.id,
          name: customer.name,
          surname: customer.surname,
        };
        if (customer.customerSelections != null) {
          state.customerSelections = [
            ...state.customerSelections,
            ...customer.customerSelections,
          ];
        }
        state.originalCustomerSelections = customer.customerSelections;
        state.changedPlans = [];
        state.showSave = this.state.showSaveAfterSignup;
        state.showSaveAfterSignup = false;

        this.activateLastCustomerSelection(state);

        return state;
      },
      () => {
        if (this.state.showSave) {
          this.props.history.push("/save" + window.location.search);
        } else {
          this.props.history.push("/" + window.location.search);
        }
      }
    );

    if (this.props.shouldStoreCookie) {
      const { cookies } = this.props;
      cookies.set("roomPlannerEmail", customer.email, { path: "/" });
    }
  };

  onPlanItemClick = (activePlanId) => {
    this.setState({
      activePlanId: activePlanId,
    });
  };

  getConfiguration = (configurationKey) => {
    axios
      .get(
        process.env.REACT_APP_APIBASE +
        "/api/v2/GetRoomConfiguration/" +
        configurationKey,
        { headers: { "Registration-Key": this.state.registrationKey } }
      )
      .then((response) => {
        this.setState((state) => {
          state.roomConfigurations = [
            ...state.roomConfigurations,
            response.data,
          ];
          state.roomConfiguration = state.roomConfiguration
            ? state.roomConfiguration
            : response.data;

          this.activateLastCustomerSelection(state);

          this.props.handleThemeChange(response.data);

          return state;
        }, this.getRoomDefinition);
      });
  };

  activateLastCustomerSelection(state) {
    if (state.roomConfiguration) {
      let activeCustomerSelectionId = 999999999999999;
      if (state.customerSelections && state.customerSelections.length > 0) {
        state.customerSelections.forEach((cs) => {
          if (
            cs.customerSelectionVariations &&
            cs.customerSelectionVariations.length > 0
          ) {
            cs.customerSelectionVariations.forEach((csv) => {
              const roomConfigurationVariation = state.roomConfiguration.roomConfigurationVariations.find(
                (v) => v.id === csv.roomConfigurationVariationId
              );
              if (
                roomConfigurationVariation &&
                state.roomConfiguration.id ===
                roomConfigurationVariation.roomConfigurationId
              ) {
                activeCustomerSelectionId =
                  activeCustomerSelectionId < csv.customerSelectionId
                    ? activeCustomerSelectionId
                    : csv.customerSelectionId;
              }
            });
          } else {
            activeCustomerSelectionId =
              activeCustomerSelectionId < cs.id
                ? activeCustomerSelectionId
                : cs.id;
          }
        });

        if (activeCustomerSelectionId === 999999999999999) {
          let newSelection = this.initializeNewCustomerSelection(state);

          state.customerSelections = [
            ...state.customerSelections,
            newSelection,
          ];
          state.activePlanId = newSelection.id;
        } else {
          state.activePlanId = activeCustomerSelectionId;
        }
      } else {
        let newSelection = this.initializeNewCustomerSelection(state);

        state.customerSelections = [newSelection];
        state.activePlanId = newSelection.id;
      }
    }
  }

  getRoomDefinition = () => {
    if (this.state.roomConfiguration) {
      axios
        .get(
          process.env.REACT_APP_APIBASE +
          "/api/v2/GetRoomDefinition/" +
          this.state.roomConfiguration.roomDefinitionId,
          { headers: { "Registration-Key": this.state.registrationKey } }
        )
        .then((response) => {
          this.setState(
            {
              roomDefinition: response.data,
            },
            this.getRoomConfirmationSettings
          );
        });
    }
  };

  getRoomConfirmationSettings() {
    axios
      .get(
        process.env.REACT_APP_APIBASE +
        "/api/v2/GetRoomConfirmationSettings/" +
        this.state.roomConfiguration.configurationKey,
        { headers: { "Registration-Key": this.state.registrationKey } }
      )
      .then((response) => {
        this.props.handleTranslationResources(
          response.data.translationResources
        );

        this.setState({
          roomConfirmationSettings: response.data,
        });
      });
  }

  onLoginClick = (e, email) => {
    axios
      .get(
        process.env.REACT_APP_APIBASE + "/api/v2/GetCustomerSelections/" + email,
        { headers: { "Registration-Key": this.state.registrationKey } }
      )
      .then((response) => {
        this.onLoginSuccessfull(response.data);
      });
  };

  onMenuClick = (id) => {
    console.log("onMenuClick", id);
    if (this.state.activeMenu && id === this.state.activeMenuId) {
      return;
    }

    this.setState({
      activeMenu: id,
      subMenuOpen: "",
    });

    setTimeout(() => {
      this.setState({
        activeMenuId: id,
        subMenuOpen: "open",
      });
    }, 400);
  };

  onMenuCloseClick = () => {
    console.log("onMenuCloseClick");
    this.setState({
      activeMenuId: 0,
      subMenuOpen: "open closing",
    });

    setTimeout(() => {
      this.setState({
        subMenuOpen: "",
      });
    }, 400);
  };

  componentDidMount() {
    if (
      this.state.configurationKeys &&
      this.state.configurationKeys.length > 0
    ) {
      this.state.configurationKeys.forEach((ck) => this.getConfiguration(ck));
    }

    const { cookies } = this.props;

    if (cookies.get("roomPlannerEmail")) {
      this.onLoginClick(null, cookies.get("roomPlannerEmail"));
    }
  }

  render() {
    return (
      <div className="scene-holder">
        <MobileHeader
          translationResources={this.props.translationResources}
          customerSelections={this.state.customerSelections}
          onShowLoginClick={this.onShowLoginClick}
          onLogoutClick={this.onLogoutClick}
          userInfo={this.state.userInfo}
          roomConfigurations={this.state.roomConfigurations}
          roomConfiguration={this.state.roomConfiguration}
          activePlanId={this.state.activePlanId}
          onPlanItemClick={this.onPlanItemClick}
          onShowPlanClick={this.onShowPlanClick}
          onConfigurationItemClick={this.onConfigurationItemClick}
          onShowRoomConfigurationsMobileClick={
            this.onShowRoomConfigurationsMobileClick
          }
        />

        <ScenePreview
          ref="scenePreview"
          translationResources={this.props.translationResources}
          theme={this.props.theme}
          invalidateThumbnail={this.invalidateThumbnail}
          activeMenuId={this.state.activeMenuId}
          onMenuClick={this.onMenuClick}
          onMenuCloseClick={this.onMenuCloseClick}
          afterRender={this.afterRender}
          roomDefinition={this.state.roomDefinition}
          roomConfigurations={this.state.roomConfigurations}
          roomConfiguration={this.state.roomConfiguration}
          customerSelections={this.state.customerSelections}
          activePlanId={this.state.activePlanId}
          onPlanItemClick={this.onPlanItemClick}
          onConfigurationItemClick={this.onConfigurationItemClick}
          onCanvasInit={this.onCanvasInit}
          showDeleteCheck={() =>
            this.props.history.push("/deletecheck" + window.location.search)
          }
        />

        <Menu
          translationResources={this.props.translationResources}
          activeMenuId={this.state.activeMenuId}
          subMenuOpen={this.state.subMenuOpen}
          activeMenu={this.state.activeMenu}
          onShowLoginClick={this.onShowLoginClick}
          onLogoutClick={this.onLogoutClick}
          userInfo={this.state.userInfo}
          customerSelections={this.state.customerSelections}
          roomConfiguration={this.state.roomConfiguration}
          activePlanId={this.state.activePlanId}
          onMenuClick={this.onMenuClick}
          onMenuCloseClick={this.onMenuCloseClick}
          onSaveClick={this.onSaveClick}
          changedPlans={this.state.changedPlans}
          onOrderClick={this.onOrderClick}
          onCustomerSelectionsChanged={this.onCustomerSelectionsChanged}
          onShowDisclaimerClick={this.onShowDisclaimerClick}
          onCreateNewClick={this.onCreateNewClick}
          onRevertClick={this.onRevertClick}
        />

        {this.state.showPlan && (
          <Saved
            translationResources={this.props.translationResources}
            customerSelections={this.state.customerSelections}
            roomConfiguration={this.state.roomConfiguration}
            activePlanId={this.state.activePlanId}
            onPlanItemMobileClick={this.onPlanItemMobileClick}
            onDeleteSelectionSuccessfull={this.onDeleteSelectionSuccessfull}
            callback={() => this.setState({ showPlan: false })}
            showDeleteCheck={() =>
              this.props.history.push("/deletecheck" + window.location.search)
            }
          />
        )}

        {this.state.showRoomConfigurationsMobile && (
          <RoomConfigurationsMobile
            translationResources={this.props.translationResources}
            onConfigurationItemClick={this.onConfigurationItemClick}
            roomConfiguration={this.state.roomConfiguration}
            roomConfigurations={this.state.roomConfigurations}
            callback={() =>
              this.setState({ showRoomConfigurationsMobile: false })
            }
          />
        )}

        <Route
          path="/login"
          render={(props) => (
            <Login
              translationResources={this.props.translationResources}
              onLoginSuccessfull={this.onLoginSuccessfull}
              onLoginClick={this.onLoginClick}
              onShowSignUpClick={this.onShowSignUpClick}
              callback={() =>
                this.props.history.push("/" + window.location.search)
              }
            />
          )}
        />

        <PrivateRoute
          path="/save"
          userInfo={this.state.userInfo}
          component={(props) => (
            <Save
              translationResources={this.props.translationResources}
              onSaveSuccessfull={this.onSaveSuccessfull}
              userInfo={this.state.userInfo}
              activePlanId={this.state.activePlanId}
              customerSelections={this.state.customerSelections}
              callback={() =>
                this.props.history.push("/" + window.location.search)
              }
              registrationKey={this.state.registrationKey}
            />
          )}
        />

        <Route
          path="/signup"
          render={(props) => (
            <SignUp
              translationResources={this.props.translationResources}
              onSignUpSuccessfull={this.onSignUpSuccessfull}
              onShowLoginClick={this.onShowLoginClick}
              callback={() =>
                this.props.history.push("/" + window.location.search)
              }
            />
          )}
        />

        <Route
          path="/register"
          render={(props) => (
            <Register
              translationResources={this.props.translationResources}
              roomConfirmationSettings={this.state.roomConfirmationSettings}
              activePlanId={this.state.activePlanId}
              onCreateNewClick={this.onCreateNewClick}
              onShowDisclaimerClick={this.onShowDisclaimerClick}
              goBack={() => this.props.history.goBack()}
              callback={() =>
                this.props.history.push("/" + window.location.search)
              }
            />
          )}
        />

        <PrivateRoute
          path="/disclaimer"
          userInfo={this.state.userInfo}
          component={(props) => (
            <Disclaimer
              translationResources={this.props.translationResources}
              activePlanId={this.state.activePlanId}
              roomConfirmationSettings={this.state.roomConfirmationSettings}
              callback={() =>
                this.props.history.push("/" + window.location.search)
              }
              goBack={() => this.props.history.goBack()}
              onShowSummaryClick={this.onShowSummaryClick}
            />
          )}
        />

        <PrivateRoute
          path="/summary"
          userInfo={this.state.userInfo}
          component={(props) => (
            <Summary
              translationResources={this.props.translationResources}
              activePlanId={this.state.activePlanId}
              roomConfiguration={this.state.roomConfiguration}
              callback={() =>
                this.props.history.push("/" + window.location.search)
              }
              goBack={() => this.props.history.goBack()}
              onConfirmationSent={this.onConfirmationSent}
              customerSelections={this.state.customerSelections}
              registrationKey={this.state.registrationKey}
            />
          )}
        />

        {this.state.showConfirmation && (
          <Confirmation
            translationResources={this.props.translationResources}
            activePlanId={this.state.activePlanId}
            roomConfiguration={this.state.roomConfiguration}
            callback={() => {
              this.props.history.push(`/?configurationKeys=${this.state.configurationKeys}&registrationKey=${this.state.registrationKey}`);
              this.setState({ showConfirmation: false });
            }}
            onConfirmationSent={this.onConfirmationSent}
            confirmationResponseType={this.state.confirmationResponseType}
          />
        )}

        <Route
          path="/deletecheck"
          component={(props) => (
            <DeleteCheck
              translationResources={this.props.translationResources}
              closeDelete={() =>
                this.props.history.push("/" + window.location.search)
              }
              activePlanId={this.state.activePlanId}
              customerSelections={this.state.customerSelections}
              onDeleteSelectionSuccessfull={this.onDeleteSelectionSuccessfull}
              callback={() =>
                this.props.history.push("/" + window.location.search)
              }
              registrationKey={this.state.registrationKey}
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(withCookies(withSnackbar(Scene)));
