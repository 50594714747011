import React, { Component } from "react";
import Link from "./Link";
import axios from "axios";
import Modal from "./Modal";
import { withSnackbar } from "notistack";
import SimpleReactValidator from "simple-react-validator";
import {getQueryVariable} from "../utils/utils";

class SignUp extends Component {
  constructor(props) {
    const registrationKey = getQueryVariable("registrationKey");
    super(props);
    this.state = {
      email: "",
      name: "",
      surname: "",
      registrationKey
    };

    this.validator = new SimpleReactValidator({});

    this.onSignUpClick = this.onSignUpClick.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onTextChange(e) {
    e.persist();

    this.setState((state) => {
      state[e.target.dataset.field] = e.target.value;

      return state;
    });
  }

  onSignUpClick() {
    if (this.validator.allValid()) {
      let customer = {
        name: this.state.name,
        surname: this.state.surname,
        email: this.state.email,
      };

      axios
        .post(
          process.env.REACT_APP_APIBASE + "/api/v2/CreateCustomer",
          customer,
          { headers: { "Registration-Key": this.state.registrationKey } }
        )
        .then((response) => {
          this.props.onSignUpSuccessfull(response.data);
        })
        .catch(function (error) {
          console.log(error.response);
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title="Sign Up"
        content={
          <div className="box-content-holder">
            <div className="box">
              <div className="box-content">
                <h2>{this.props.translationResources["Welcome!"]}</h2>
                <div className="name-surname-holder">
                  <div className="row">
                    <input
                      type="text"
                      placeholder={this.props.translationResources["NAME"]}
                      data-field="name"
                      onChange={this.onTextChange}
                    />
                    {this.validator.message(
                      "name",
                      this.state.name,
                      "required|min:2"
                    )}
                  </div>
                  <div className="row">
                    <input
                      type="text"
                      placeholder={this.props.translationResources["SURNAME"]}
                      data-field="surname"
                      onChange={this.onTextChange}
                    />
                    {this.validator.message(
                      "surname",
                      this.state.surname,
                      "required|min:2"
                    )}
                  </div>
                </div>
                <input
                  type="email"
                  placeholder={
                    this.props.translationResources["E-MAIL ADDRESS"]
                  }
                  data-field="email"
                  onChange={this.onTextChange}
                />
                {this.validator.message(
                  "email",
                  this.state.email,
                  "required|email"
                )}
              </div>
              <div className="btn-holder">
                <Link
                  onClick={this.props.onShowLoginClick}
                  className="grey-links"
                >
                  {
                    this.props.translationResources[
                      "EXISTING USER? PLEASE LOGIN"
                    ]
                  }
                </Link>
                <Link onClick={this.onSignUpClick} className="btn">
                  {this.props.translationResources["SIGN UP"]}
                </Link>
              </div>
            </div>
          </div>
        }
        callback={this.props.callback}
      />
    );
  }
}

export default withSnackbar(SignUp);
