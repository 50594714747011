import React, { Component } from "react";
import "./index.scss";
import Scene from "./components/Scene";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { withSnackbar } from "notistack";

import LandscapeCheck from "./components/LandscapeCheck";
import ThemeSwitcher from "react-css-vars";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingRequestCount: 0,
      theme: {
        dotColor: "#ffc500",
        buttonColor: "#ffc500",
        borderColor: "#ffc500",
      },
      translationResources: {},
    };

    // Add a request interceptor
    axios.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        this.setState((state) => {
          state.pendingRequestCount++;

          return state;
        });

        return config;
      },
      (error) => {
        // Do something with request error
        this.setState((state) => {
          state.pendingRequestCount--;

          return state;
        });

        this.props.enqueueSnackbar("Error occured: ".concat(error), {
          variant: "error",
        });

        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        // Do something with response data
        if (response.data.status === 1) {
          this.props.enqueueSnackbar(response.data.error, { variant: "error" });
        } else if (response.config.snackbarMessage) {
          this.props.enqueueSnackbar(response.config.snackbarMessage);
        }

        this.setState((state) => {
          state.pendingRequestCount--;

          return state;
        });

        return response;
      },
      (error) => {
        // Do something with response error
        var msg = error;

        if (error && error.response) {
          if (error.response.status === 401) {
            msg = "Authentication failed. (401)";
          } else if (error.response.status === 404) {
            msg = "Page not found. (404)";
          } else if (
            error.response.status === 500 &&
            error.response.data &&
            error.response.data.Data === false
          ) {
            msg = "Internal server error (500): " + error.response.data.Error;
          } else if (
            error.response.data &&
            error.response.data.Data === false
          ) {
            msg = error.response.data.Error;
          } else if (error.response.status === 503) {
            msg = "Network error.(503)";
          } else if (error.response.status === 400) {
            msg = "";

            for (var key in error.response.data) {
              msg += error.response.data[key] + "\n";
            }
          }
        } else {
          msg = "Network error: " + error;
        }

        if (msg) this.props.enqueueSnackbar(msg, { variant: "error" });

        this.setState((state) => {
          state.pendingRequestCount--;

          return state;
        });

        return Promise.reject(error);
      }
    );
  }

  handleThemeChange = (configuration) => {
    if (configuration && configuration.dotColor) {
      this.setState({
        theme: {
          dotColor: configuration.dotColor,
          buttonColor: configuration.buttonColor,
          borderColor: configuration.borderColor,
        },
      });
    }
  };

  handleTranslationResources = (translationResources) => {
    const jsonString = "{" + translationResources + "}";
    const parsedObject = JSON.parse(jsonString);
    this.setState({ translationResources: parsedObject });
  };

  render() {
    return (
      <ThemeSwitcher theme={this.state.theme}>
        <LoadingOverlay
          active={this.state.pendingRequestCount > 0}
          fadeSpeed={100}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.6)",
            }),
          }}
          spinner
        >
          <div className="app-holder">
            <LandscapeCheck
              translationResources={this.state.translationResources}
            />
            <Scene
              handleTranslationResources={this.handleTranslationResources}
              translationResources={this.state.translationResources}
              handleThemeChange={this.handleThemeChange}
              theme={this.state.theme}
            />
          </div>
        </LoadingOverlay>
      </ThemeSwitcher>
    );
  }
}

export default withSnackbar(App);
