import React, { Component } from "react";
import ReactDOM from "react-dom";
import Link from "./Link";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      title: this.props.title,
      content: this.props.content,
    };
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.show !== this.props.show) {
      return;
    }
    const { show, title, content } = nextProps;
    this.setState({ show, title, content });
  }

  onCloseClick() {
    this.setState({
      show: false,
      title: null,
      content: null,
    });

    if (this.props.callback) this.props.callback();
  }

  render() {
    const modal_holder_class = this.props.contentClass
      ? this.props.contentClass + " content-holder"
      : "content-holder";
    const modal_class = this.state.show
      ? "modal-holder active"
      : "modal-holder";
    return ReactDOM.createPortal(
      <div className={modal_class} onClick={(e) => e.stopPropagation()}>
        <div className={modal_holder_class}>
          <div className="title">{this.state.title}</div>
          <div className="content">
            {this.state.content}
            <Link
              className="close-btn"
              onClick={() => {
                this.onCloseClick();
              }}
            >
              {this.props.translationResources["Close"]}
            </Link>
          </div>
        </div>
      </div>,
      document.querySelector("#modal")
    );
  }
}

export default Modal;
