import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({ component: Component, ...props }) {
    return (
        props && props.userInfo ? (
            <Route
                {...props}
                render={(props) =>
                    <Component {...props} />
                }
            />
        ) :
            <Redirect
                to={{
                    pathname: "/" + window.location.search,
                    state: { from: props.location }
                }}
            />
    );
}