import React, { Component } from "react";
import Link from "./Link";
import axios from "axios";
import Modal from "./Modal";
import CustomScroll from "react-custom-scroll";

class Summary extends Component {
  constructor(props) {
    super(props);

    this.onConfirmClick = this.onConfirmClick.bind(this);
  }

  onConfirmClick() {
    if (this.props.activePlanId > 0) {
      let axiosRequestConfig = {
        snackbarMessage: this.props.translationResources[
          "Confirmation mail sent. Please check your emails."
        ],
      };

      axios
        .post(
          process.env.REACT_APP_APIBASE +
          "/api/v2/SendCustomerSelectionConfirmationEmail/" +
          this.props.activePlanId,
          axiosRequestConfig,
          { headers: { "Registration-Key": this.props.registrationKey } }
        )
        .then((response) => {
          this.props.onConfirmationSent();
        });
    }
  }

  renderSummaryItems = () => {
    let items = [];

    this.props.customerSelections
      .find((cs) => cs.id === this.props.activePlanId)
      .customerSelectionVariations.forEach((csv) => {
        this.props.roomConfiguration.roomConfigurationVariations.forEach(
          (rcv) => {
            if (rcv.id === csv.roomConfigurationVariationId) {
              items.push(
                <div key={rcv.id} className="summary-item">
                  <span>{rcv.roomDefinitionLayerName}</span>
                  <img
                    src={process.env.REACT_APP_APIBASE + rcv.materialImageUrl}
                    alt={rcv.name}
                  />
                  {rcv.name}
                </div>
              );
            }
          }
        );
      });

    return items;
  };

  render() {
    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        contentClass="summary-holder"
        title={this.props.translationResources["Summary of Order"]}
        content={
          <div>
            <Link
              onClick={() => {
                this.props.goBack();
              }}
              className="go-back-btn"
            >
              {this.props.translationResources["Go Back"]}
            </Link>
            <div className="box-content-holder summary">
              <div className="box">
                <div className="box-content">
                  <h2>{this.props.translationResources["Selected Options"]}</h2>
                  <div className="summary-items-holder">
                    <CustomScroll heightRelativeToParent="calc(100%)">
                      <div className="summary-items">
                        {this.renderSummaryItems()}
                      </div>
                    </CustomScroll>
                  </div>
                </div>
                <div className="btn-holder align-right">
                  <Link
                    className="btn btn-discalimer"
                    onClick={this.onConfirmClick}
                  >
                    {this.props.translationResources["CONFIRM"]}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
        callback={this.props.callback}
      />
    );
  }
}

export default Summary;
