import React, { Component } from "react";
import Modal from "./Modal";

class RoomConfigurationsMobile extends Component {
  render() {
    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title={this.props.translationResources["Room Types"]}
        contentClass="saved-modal-holder"
        content={
          this.props.roomConfigurations &&
          this.props.roomConfigurations.length > 0 ? (
            <div className="saved-holder">
              {this.props.roomConfigurations.map((rc) => {
                let itemClass = "";
                if (this.props.roomConfiguration.id === rc.id)
                  itemClass = itemClass + " active";

                let itemStyle = {};
                if (rc.baseImageUrl) {
                  itemStyle.backgroundImage = rc.baseImageUrl.startsWith(
                    "data:image"
                  )
                    ? `url(${rc.baseImageUrl})`
                    : `url(${process.env.REACT_APP_APIBASE + rc.baseImageUrl})`;
                } else {
                  itemStyle.backgroundColor = "grey";
                }
                return (
                  <div key={rc.id || rc.name} className={itemClass}>
                    <div
                      onClick={() => this.props.onConfigurationItemClick(rc)}
                      className="saved-item"
                    >
                      <div className="item-bg" style={itemStyle} />
                      <p>{rc.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              {this.props.translationResources["No configuration found"]}
            </div>
          )
        }
        callback={this.props.callback}
      />
    );
  }
}

export default RoomConfigurationsMobile;
