import React, { Component } from "react";
import Link from "./Link";
import Modal from "./Modal";
import CustomScroll from "react-custom-scroll";
var HtmlToReactParser = require("html-to-react").Parser;

class Disclaimer extends Component {
  render() {
    let htmlToReactParser = new HtmlToReactParser();
    const legalText = htmlToReactParser.parse(
      this.props.roomConfirmationSettings.legalText
    );

    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title={this.props.translationResources["Confirm Order"]}
        contentClass="disclaimer-holder"
        content={
          <div>
            <Link
              onClick={() => {
                this.props.goBack();
              }}
              className="go-back-btn"
            >
              {this.props.translationResources["Go Back"]}
            </Link>
            <div className="box-content-holder disclaimer">
              <div className="box">
                <div className="box-content">
                  <h2>{this.props.translationResources["Legal Terms"]}</h2>
                  <div className="legal-terms">
                    <CustomScroll heightRelativeToParent="calc(100%)">
                      {legalText}
                    </CustomScroll>
                  </div>
                </div>
                <div className="btn-holder align-right">
                  <Link
                    className="btn btn-discalimer"
                    onClick={this.props.onShowSummaryClick}
                  >
                    {this.props.translationResources["ACCEPT"]}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
        callback={this.props.callback}
      />
    );
  }
}

export default Disclaimer;
