import React, { Component } from "react";
import Link from "./Link";
import Modal from "./Modal";
import SimpleReactValidator from "simple-react-validator";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
    };

    this.validator = new SimpleReactValidator();

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(e) {
    if (this.validator.allValid()) {
      this.props.onLoginClick(e, this.state.email);
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  render() {
    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title={this.props.translationResources["Login"]}
        content={
          <div className="box-content-holder">
            <div className="box">
              <div className="box-content">
                <h2>{this.props.translationResources["Welcome Back!"]}</h2>
                <input
                  type="email"
                  placeholder={
                    this.props.translationResources["E-MAIL ADDRESS"]
                  }
                  onChange={this.onEmailChange}
                />
                {this.validator.message(
                  "email",
                  this.state.email,
                  "required|email"
                )}
              </div>
              <div className="btn-holder">
                <Link
                  onClick={(e) =>
                    this.props.onShowSignUpClick(e, this.state.email)
                  }
                  className="grey-links"
                >
                  {
                    this.props.translationResources[
                      "NOT A USER? PLEASE SIGN UP"
                    ]
                  }
                </Link>
                <Link onClick={this.onFormSubmit} className="btn">
                  {this.props.translationResources["LOGIN"]}
                </Link>
              </div>
            </div>
          </div>
        }
        callback={this.props.callback}
      />
    );
  }
}

export default Login;
