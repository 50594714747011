import React, { Component } from "react";
import PlanSelector from "./PlanSelector";
import RoomSelector from "./RoomSelector";
import { fabric } from "fabric";
import axios from "axios";
import btnPlus from "../images/btn-plus.svg";

class ScenePreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roomDefinition: { id: -1 },
      activePlanId: null,
      scale: 1,
    };

    this.imageList = [];
    this.urlList = [];
    this.groups = [];
    this.dots = [];
    this.previewHolder = React.createRef();
    this.canvas = React.createRef();
    this.originalImageWidth = 0;
    this.originalImageHeight = 0;
    this.currentZoomCentre = new fabric.Point(0, 0);
    this.isInZoomState = false;

    window.addEventListener("resize", () => {
      this.updateDimensions();
    });

    this.renderCanvas = this.renderCanvas.bind(this);
  }

  componentDidMount() {
    this.initialiseCanvas();
  }

  initialiseCanvas() {
    const { clientWidth, clientHeight } = this.previewHolder.current;
    const canvas = new fabric.Canvas("canvasHolder", {
      selection: false,
      backgroundColor: "white",
      objectCaching: false,
      renderOnAddRemove: true,
    });

    canvas.on("after:render", this.props.afterRender);
    canvas.setWidth(clientWidth);
    canvas.setHeight(clientHeight);
    canvas.renderAll();
    canvas.getContext().imageSmoothingEnabled = true;
    this.fabricCanvas = canvas;
    this.imageList = [];
    this.dots = [];
    this.fabricCanvas.renderAll();
    this.updateDimensions();

    if (this.props.onCanvasInit) {
      this.props.onCanvasInit(this.fabricCanvas, this.state.scale);
    }
  }

  renderCanvas(nextProps) {
    if (nextProps.roomDefinition) {
      if (
        nextProps.roomDefinition.id !== this.state.roomDefinition.id ||
        nextProps.activePlanId !== this.state.activePlanId
      ) {
        if (nextProps.roomDefinition.id !== this.state.roomDefinition.id) {
          this.fabricCanvas.clear();
          this.fabricCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
          this.fabricCanvas.setBackgroundColor("white", null);
          this.fabricCanvas.renderAll();
          this.originalImageWidth = 0;
          this.originalImageHeight = 0;
          this.urlList = [];
          this.groups = [];

          for (const dot of this.dots) {
            dot.off("mousedown");
          }

          this.dots = [];
          this.imageList = [];

          this.createGroups(
            nextProps.roomDefinition.roomDefinitionLayers.length
          );

          let source =
            process.env.REACT_APP_APIBASE +
            nextProps.roomDefinition.baseImageUrl;
          this.addImageToScene(0, source);

          this.addDots();
        }
      }

      this.setState({
        roomDefinition: nextProps.roomDefinition,
        activePlanId: nextProps.activePlanId,
        layers: nextProps.roomDefinition.roomDefinitionLayers,
      });

      if (nextProps.activeMenuId !== this.props.activeMenuId) {
        if (nextProps.activeMenuId) {
          this.zoomToLevel(nextProps.activeMenuId);
          for (const dot of this.dots) {
            dot.visible = false;
          }
        } else {
          this.resetZoom();
          for (const dot of this.dots) {
            dot.visible = true;
          }
        }
      }
    }

    let images = [];

    if (
      nextProps.customerSelections &&
      nextProps.customerSelections.length > 0 &&
      nextProps.activePlanId !== null &&
      nextProps.activePlanId !== 0 &&
      nextProps.roomConfiguration
    ) {
      let activeCustomerSelection = nextProps.customerSelections.find(
        (customerSelection) => customerSelection.id === nextProps.activePlanId
      );

      if (
        activeCustomerSelection &&
        activeCustomerSelection.customerSelectionVariations &&
        activeCustomerSelection.customerSelectionVariations.length > 0
      ) {
        activeCustomerSelection.customerSelectionVariations.forEach(
          (customerSelectionVariation) => {
            if (nextProps.roomConfiguration.roomConfigurationVariations) {
              let roomConfigurationVariation = nextProps.roomConfiguration.roomConfigurationVariations.find(
                (v) =>
                  v.id ===
                  customerSelectionVariation.roomConfigurationVariationId
              );

              if (
                roomConfigurationVariation &&
                nextProps.roomDefinition &&
                nextProps.roomDefinition.roomDefinitionLayers &&
                nextProps.roomDefinition.roomDefinitionLayers.length > 0
              ) {
                let roomDefinitionLayer = nextProps.roomDefinition.roomDefinitionLayers.find(
                  (l) =>
                    l.id === roomConfigurationVariation.roomDefinitionLayerId
                );

                if (roomDefinitionLayer) {
                  images.push({
                    sortOrder: roomDefinitionLayer.order,
                    url:
                      process.env.REACT_APP_APIBASE +
                      roomConfigurationVariation.renderedImageUrl,
                  });
                }
              }
            }
          }
        );
      }

      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          const image = images[i];
          try {
            if (this.urlList[image.sortOrder + 1] == null) {
              this.addImageToScene(image.sortOrder + 1, image.url);
            } else {
              if (this.urlList[image.sortOrder + 1] !== image.url) {
                this.updateImage(image.sortOrder + 1, image.url);
              }
            }
          } catch (e) {
            console.log("error" + e);
          }
        }
      }
    }
  }

  createGroups(layerCount) {
    for (let i = 0; i < layerCount + 1; i++) {
      var layerContainer = new fabric.Group();
      layerContainer.centeredScaling = true;
      layerContainer.hasControls = false;
      layerContainer.selectable = false;
      layerContainer.hasBorders = false;
      layerContainer.hoverCursor = "default";
      this.groups[i] = layerContainer;
      this.fabricCanvas.add(layerContainer);
    }
  }

  zoomToLevel(layerId) {
    var xPos = this.state.roomDefinition.roomDefinitionLayers.find(
      (l) => l.id === layerId
    ).indicatorPositionX;
    var yPos = this.state.roomDefinition.roomDefinitionLayers.find(
      (l) => l.id === layerId
    ).indicatorPositionY;
    let canvasToImagescale =
      this.fabricCanvas.getHeight() / this.originalImageHeight;
    var zoomCenter = new fabric.Point(
      xPos * canvasToImagescale,
      yPos * canvasToImagescale
    );

    if (this.isInZoomState) {
      this.fabricCanvas.relativePan(
        new fabric.Point(
          this.currentZoomCentre.x - zoomCenter.x,
          this.currentZoomCentre.y - zoomCenter.y
        )
      );
      this.limitTransform();
      this.currentZoomCentre = zoomCenter;
    } else {
      this.currentZoomCentre = zoomCenter;
      this.zoomIn(this);
      this.fabricCanvas.renderAll();
    }

    this.isInZoomState = true;
  }

  resetZoom() {
    this.zoomOut(this);
    this.isInZoomState = false;
  }

  zoomIn(targetObj) {
    const fabricCanvas = targetObj.fabricCanvas;
    var point = targetObj.currentZoomCentre;
    const targetScale = 1.4 * targetObj.state.scale;
    const zoomIn = targetObj.zoomIn;
    setTimeout(function () {
      var zoomValue = fabricCanvas.getZoom() + 0.02;
      if (zoomValue < targetScale) {
        fabricCanvas.zoomToPoint(point, zoomValue);
        targetObj.limitTransform();
        fabricCanvas.requestRenderAll();
        zoomIn(targetObj);
      }
    }, 20);
  }

  zoomOut(targetObj) {
    const fabricCanvas = targetObj.fabricCanvas;
    var point = targetObj.currentZoomCentre;
    const zoomOut = targetObj.zoomOut;
    const targetScale = targetObj.state.scale;
    setTimeout(function () {
      var zoomValue = fabricCanvas.getZoom() - 0.02;
      if (zoomValue > targetScale) {
        fabricCanvas.zoomToPoint(point, zoomValue);
        targetObj.limitTransform();
        fabricCanvas.requestRenderAll();
        zoomOut(targetObj);
      }
    }, 20);
  }

  limitTransform() {
    if (this.originalImageWidth === 0 || this.originalImageHeight === 0) return;

    let vpt = this.fabricCanvas.viewportTransform;
    let zoom = this.fabricCanvas.getZoom();

    let canvasWidth = this.fabricCanvas.getWidth();
    let backgroundWidth = this.originalImageWidth;
    let backgroundHeight = this.originalImageHeight;
    let canvasHeight = this.fabricCanvas.getHeight();

    var minZoom = Math.max(
      canvasWidth / backgroundWidth,
      canvasHeight / backgroundHeight
    );
    if (zoom < minZoom) {
      this.fabricCanvas.setZoom(minZoom);
    }

    if (vpt[4] >= 0) {
      this.fabricCanvas.viewportTransform[4] = 0;
    } else if (vpt[4] < canvasWidth - backgroundWidth * zoom) {
      this.fabricCanvas.viewportTransform[4] =
        canvasWidth - backgroundWidth * zoom;
    }

    if (vpt[5] >= 0) {
      this.fabricCanvas.viewportTransform[5] = 0;
    } else if (vpt[5] < canvasHeight - backgroundHeight * zoom) {
      this.fabricCanvas.viewportTransform[5] =
        canvasHeight - backgroundHeight * zoom;
    }
  }

  addDots() {
    var that = this;
    fabric.util.loadImage(btnPlus, (image) => {
      for (const layer of that.state.roomDefinition.roomDefinitionLayers) {
        let plusIcon = new fabric.Image(image);
        plusIcon.left = layer.indicatorPositionX + 4;
        plusIcon.top = layer.indicatorPositionY + 4;
        plusIcon.width = 11;
        plusIcon.height = 11;

        let dot = new fabric.Circle({
          fill: "white",
          opacity: 1,
          radius: 20,
          strokeWidth: 10,
          stroke: this.props.theme.dotColor,
          left: layer.indicatorPositionX,
          top: layer.indicatorPositionY,
          width: 10,
          height: 10,
          name: layer,
        });

        var dotButton = new fabric.Group([dot, plusIcon], {
          left: layer.indicatorPositionX,
          top: layer.indicatorPositionY,
          width: 50,
          height: 50,
          id: "dotButton",
          hasControls: false,
          selectable: false,
          hasRotatingPoint: false,
          hoverCursor: "pointer",
        });

        dotButton.on("mousedown", (event) => {
          if (this.props.roomConfiguration) {
            this.props.onMenuClick(layer.id);
          }
        });

        this.fabricCanvas.add(dotButton);
        this.dots.push(dotButton);
      }
    });
  }

  componentWillReceiveProps(nextProps, nextContent) {
    this.renderCanvas(nextProps);
  }

  addImageToScene(layerIndex, source) {
    let that = this;
    that.urlList[layerIndex] = source;

    axios
      .get(source, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var myDataURL =
          "data:" +
          response.headers["content-type"] +
          ";base64," +
          Buffer.from(response.data, "binary").toString("base64");

        fabric.Image.fromURL(
          myDataURL,
          function (img) {
            if (
              that.originalImageWidth === 0 ||
              that.originalImageHeight === 0
            ) {
              that.originalImageWidth = img.width;
              that.originalImageHeight = img.height;
              // console.log(that.originalImageWidth);
              that.updateDimensions();
            }

            that.groups[layerIndex].addWithUpdate(img);
            that.imageList[layerIndex] = img;

            that.props.invalidateThumbnail();
          },
          {
            selectable: false,
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            noScaleCache: false,
          }
        );
      });
  }

  updateImage(level, url) {
    if (this.urlList[level] !== url) {
      this.urlList[level] = url;
      this.groups[level].remove(this.imageList[level]);
      this.addImageToScene(level, url);
    }
  }

  updateDimensions = () => {
    const { clientWidth, clientHeight } = this.previewHolder.current;
    this.fabricCanvas.setWidth(clientWidth);
    this.fabricCanvas.setHeight(clientHeight);

    if (this.groups.length > 0) {
      this.updateScale();
    }
    this.fabricCanvas.requestRenderAll();
  };

  updateScale() {
    let canvasWidth = this.fabricCanvas.getWidth();
    let canvasHeight = this.fabricCanvas.getHeight();
    const scale = Math.max(
      canvasWidth / this.originalImageWidth,
      canvasHeight / this.originalImageHeight
    );
    this.setState({ scale: scale }, () =>
      this.fabricCanvas.setZoom(this.state.scale)
    );

    if (this.props.onCanvasInit) {
      this.props.onCanvasInit(this.fabricCanvas, this.state.scale);
    }
  }

  render() {
    return (
      <div className="scene">
        <RoomSelector
          roomConfiguration={this.props.roomConfiguration}
          roomConfigurations={this.props.roomConfigurations}
          onConfigurationItemClick={this.props.onConfigurationItemClick}
        />
        <div className="preview-holder" ref={this.previewHolder}>
          <canvas id="canvasHolder" ref={this.canvas} />
          <div className="area-pointer" style={{ display: "none" }}>
            +
          </div>
        </div>
        <PlanSelector
          roomConfiguration={this.props.roomConfiguration}
          customerSelections={this.props.customerSelections}
          activePlanId={this.props.activePlanId}
          onPlanItemClick={this.props.onPlanItemClick}
          showDeleteCheck={this.props.showDeleteCheck}
        />
      </div>
    );
  }
}

export default ScenePreview;
