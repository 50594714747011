import React, { Component } from "react";

class PlanSelector extends Component {
  render() {
    let filteredCustomerSelections = [];

    if (
      this.props.roomConfiguration &&
      this.props.roomConfiguration.roomConfigurationVariations &&
      this.props.roomConfiguration.roomConfigurationVariations.length > 0 &&
      this.props.customerSelections &&
      this.props.customerSelections.length > 0
    ) {
      this.props.customerSelections.forEach((cs) => {
        if (
          cs.customerSelectionVariations &&
          cs.customerSelectionVariations.length > 0
        ) {
          cs.customerSelectionVariations.forEach((csv) => {
            const roomConfigurationVariation = this.props.roomConfiguration.roomConfigurationVariations.find(
              (v) => v.id === csv.roomConfigurationVariationId
            );

            if (
              roomConfigurationVariation &&
              this.props.roomConfiguration.id ===
                roomConfigurationVariation.roomConfigurationId
            ) {
              const customerSelection = this.props.customerSelections.find(
                (cs) => cs.id === csv.customerSelectionId
              );

              if (
                customerSelection &&
                filteredCustomerSelections.some(
                  (fcs) => fcs.id === customerSelection.id
                ) === false
              ) {
                filteredCustomerSelections.push(customerSelection);
              }
            }
          });
        } else {
          filteredCustomerSelections.push(cs);
        }
      });
    }

    if (filteredCustomerSelections && filteredCustomerSelections.length > 0) {
      filteredCustomerSelections.sort((a, b) => {
        return b.id - a.id;
      });
    }

    return (
      <div className="plan-selector">
        {filteredCustomerSelections.map((cs) => {
          let itemClass = "planner-box";
          if (this.props.activePlanId === cs.id)
            itemClass = itemClass + " active";

          let itemStyle = {};
          if (cs.thumbnailUrl) {
            itemStyle.backgroundImage = cs.thumbnailUrl.startsWith("data:image")
              ? `url(${cs.thumbnailUrl})`
              : `url(${process.env.REACT_APP_APIBASE + "/" + cs.thumbnailUrl})`;
          } else {
            itemStyle.backgroundColor = "grey";
          }

          return (
            <div key={cs.id || cs.name}>
              <div
                onClick={() => this.props.onPlanItemClick(cs.id)}
                className={itemClass}
              >
                {filteredCustomerSelections.length > 1 && (
                  <div
                    className="plan-delete-btn"
                    onClick={() => this.props.showDeleteCheck(cs.id)}
                  />
                )}
                <div className="item-bg" style={itemStyle}></div>
                <p>{cs.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PlanSelector;
