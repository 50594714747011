import React, { Component } from "react";
import Link from "./Link";

import saved_icon from '../images/saved-icon.svg';
import login_icon from '../images/user-icon.svg';
import logout_icon from '../images/logout-icon.svg';

class MobileHeader extends Component {
  constructor(props) {
    super(props);

    this.onPlanItemMobileClick = this.onPlanItemMobileClick.bind(this);
  }

  onPlanItemMobileClick(customerSelectionId) {
    this.props.onModalCloseCallback();
    this.props.onPlanItemClick(customerSelectionId);
  }

  render() {
    let user_icon = this.props.userInfo && this.props.userInfo.id > 0 ? logout_icon : login_icon;

    return (
      <div className="mobile-header">
        <div>
          {this.props.roomConfiguration && this.props.roomConfigurations && this.props.roomConfigurations.length > 1 ?
            <Link
              className="btn-icon plan-switcher"
              key={this.props.roomConfiguration.id}
              onClick={this.props.onShowRoomConfigurationsMobileClick}
            >
              {this.props.roomConfiguration.name}
            </Link>
            :
            <div className="btn-icon plan-switcher">
              {this.props.roomConfiguration && this.props.roomConfiguration.name}
            </div>
          }
        </div>
        <div>
          {this.props.customerSelections && this.props.customerSelections.length > 1 &&
            <Link
              className="btn-icon"
              onClick={() => {
                this.props.onShowPlanClick();
              }}
            >
              <img src={saved_icon} alt="Saved" />
            </Link>
          }
          <Link
            className="btn-icon"
            onClick={() => {
              if (this.props.userInfo && this.props.userInfo.id > 0) {
                this.props.onLogoutClick();
              }
              else {
                this.props.onShowLoginClick();
              }
            }}
          >
            <img src={user_icon} alt="Login" />
          </Link>
        </div>
      </div>
    );
  }
}

export default MobileHeader;
