import React, { Component } from "react";
import Modal from "./Modal";

class Saved extends Component {
  render() {
    let filteredCustomerSelections = [];

    if (
      this.props.roomConfiguration &&
      this.props.roomConfiguration.roomConfigurationVariations &&
      this.props.roomConfiguration.roomConfigurationVariations.length > 0 &&
      this.props.customerSelections &&
      this.props.customerSelections.length > 0
    ) {
      this.props.customerSelections.forEach((cs) => {
        if (
          cs.customerSelectionVariations &&
          cs.customerSelectionVariations.length > 0
        ) {
          cs.customerSelectionVariations.forEach((csv) => {
            const roomConfigurationVariation = this.props.roomConfiguration.roomConfigurationVariations.find(
              (v) => v.id === csv.roomConfigurationVariationId
            );

            if (
              roomConfigurationVariation &&
              this.props.roomConfiguration.id ===
                roomConfigurationVariation.roomConfigurationId
            ) {
              const customerSelection = this.props.customerSelections.find(
                (cs) => cs.id === csv.customerSelectionId
              );

              if (
                customerSelection &&
                filteredCustomerSelections.some(
                  (fcs) => fcs.id === customerSelection.id
                ) === false
              ) {
                filteredCustomerSelections.push(customerSelection);
              }
            }
          });
        } else {
          filteredCustomerSelections.push(cs);
        }
      });
    }

    if (filteredCustomerSelections && filteredCustomerSelections.length > 0) {
      filteredCustomerSelections.sort((a, b) => {
        return b.id - a.id;
      });
    }

    const items =
      filteredCustomerSelections && filteredCustomerSelections.length > 0 ? (
        <div className="saved-holder">
          {filteredCustomerSelections.map((cs) => {
            let itemClass = "";
            if (this.props.activePlanId === cs.id)
              itemClass = itemClass + " active";

            let itemStyle = {};
            if (cs.thumbnailUrl) {
              itemStyle.backgroundImage = cs.thumbnailUrl.startsWith(
                "data:image"
              )
                ? `url(${cs.thumbnailUrl})`
                : `url(${process.env.REACT_APP_APIBASE + cs.thumbnailUrl})`;
            } else {
              itemStyle.backgroundColor = "grey";
            }
            return (
              <div key={cs.id || cs.name} className={itemClass}>
                <div
                  onClick={() => this.props.onPlanItemMobileClick(cs.id)}
                  className="saved-item"
                >
                  <div
                    className="plan-delete-btn"
                    onClick={() => this.props.showDeleteCheck(cs.id)}
                  />
                  <div className="item-bg" style={itemStyle} />
                  <p>{cs.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>{this.props.translationResources["No saved item"]} </div>
      );

    return (
      <Modal
        show
        translationResources={this.props.translationResources}
        title={this.props.translationResources["Saved"]}
        contentClass="saved-modal-holder"
        content={items}
        callback={this.props.callback}
      />
    );
  }
}

export default Saved;
